<template>
  <div class="box">
    <div class="l_nav">
      <ul>
        <li @click="goIndex"><a href="javascript:;">首页 </a></li>
        <li><span>/</span></li>
        <li>{{ params.name }}</li>
      </ul>
    </div>
    <div class="content">
      <template v-if="query.navigationType == 1">
        <ul>
          <li v-for="(item, index) in dates" :key="index" @click="goDetails(item.id)">
            <span class="circular" :style="'background:' + color"></span>
            <span class="liText">{{ item.journalisTitle }}</span>
            <span class="liTime">{{ toDate(item.releaseTime, "yyyy-MM-dd") }}</span>
          </li>
        </ul>
        <el-pagination class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current" :page-size="query.size" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
      </template>
      <template v-else>
        <div class="title">{{ details.journalisTitle }}</div>
        <div class="title_m">
          <span>信息来源：{{ details.message }}</span>
          <span>发布日期：{{ toDate(details.createTime, "yyyy-MM-dd") }}</span>
        </div>
        <div class="ql-editor" v-html="text"></div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { selectNavigaionList, getTextarea } from "@/api/template";
export default {
  name: "Transfer",
  computed: {
    ...mapState(["color"]),
  },
  data() {
    return {
      params: "",
      query: {
        current: 1,
        navigationId: "",
        navigationType: "",
        size: 10,
      },
      total: 10,
      dates: [],
      details: {},
      text: "",
    };
  },
  created() {
    // this.params = JSON.parse(window.sessionStorage.getItem("navigationValue"));
    this.params = this.$route.params
    this.init();
  },
  methods: {
    init() {
      this.query.navigationId = this.params.id;
      this.query.navigationType = this.params.type;
      selectNavigaionList(this.query).then((res) => {
        if (this.params.type == 1) {
          this.dates = res.data.records;
        } else {
          this.details = res.data;
          this.getTextareaFun(res.data.journalisContent);
        }
        this.total = res.data.total;
      }).catch(() => {});
    },
    getTextareaFun(journalisContent) {
      let params = {
        journalisContent: journalisContent,
      };
      getTextarea(params).then((res) => {
        this.text = res.data.dataHtml;
      });
    },
    handleSizeChange(size) {
      this.query.size = size;
      this.init();
    },
    handleCurrentChange(current) {
      this.query.current = current;
      this.init();
    },
    goIndex() {
      this.$router.push("./template-first");
    },
    goDetails(id) {
      let data = {
        name: this.params.name,
        id: id,
      };
      window.sessionStorage.setItem("detailsValue", JSON.stringify(data));
      this.$router.push("/first-details");
    },
  },
};
</script>
<style lang="scss" scoped>
.l_nav {
  height: 40px;
  background-color: #f7f7f7;
  border-bottom: 1px #d2d2d2 solid;
  ul {
    width: 1000px;
    margin: 0 auto;
    li {
      float: left;
      line-height: 40px;
      span {
        padding: 0 10px;
      }
    }
  }
}
.content {
  width: 960px;
  min-height: 420px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  li {
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    color: #545454;
    font-size: 14px;
    .circular {
      display: inline-block;
      width: 5px;
      height: 5px;
      background: #1e3c5a;
      border-radius: 5px;
      vertical-align: middle;
      margin-right: 10px;
    }
    .liText {
      display: inline-block;
      width: 800px;
      height: 30px;
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .liTime {
      float: right;
    }
  }
  .pagination {
    border-top: 1px solid #d2d2d2;
    padding-top: 20px;
    margin-top: 20px;
  }
  .title {
    color: #1e3c5a;
    font-size: 26px;
    text-align: center;
    line-height: 80px;
    font-weight: bold;
  }
  .title_m {
    text-align: center;
    border-bottom: 1px solid #d7d7d7;
    padding: 20px;
    font-size: 14px;
    span {
      margin-right: 30px;
    }
  }
  .ql-editor {
    padding: 20px 0;
    line-height: 30px;
  }
}
.el-pager .active {
  color: #1e3c5a !important;
}
</style>
